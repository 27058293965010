import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import { httpClientIntegration } from '@sentry/integrations';
import config from '#config';

const init = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [browserTracingIntegration(), httpClientIntegration()],
    environment: config.SENTRY_ENV,
  });
};

export default { init };
